import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const PaginationStyles = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid var(--background);
  margin: -2rem auto 2rem;
  border-radius: 5px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  font-size: 0.9rem;

  & > * {
    margin-inline-start: 0.25rem;
    margin-inline-end: 0.25rem;
    &[aria-current],
    &.current {
      color: var(--primaryPop);
      pointer-events: none;
    }
    &[disabled] {
      pointer-events: none;
      color: var(--background);
      display: none;
    }
  }
`;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;
  return (
    <PaginationStyles>
      <Link
        disabled={!hasPrevPage}
        to={prevPage === 1 ? `${base}` : `${base}/${prevPage}`}
      >
        ⬅ Prev
      </Link>
      {Array.from({ length: totalPages }).map((_, i) => {
        if (currentPage === 1 && !hasNextPage) return;
        return (
          <Link
            className={currentPage === 1 && i === 0 ? "current" : ""}
            key={`page${i}`}
            to={`${base}/${i > 0 ? i + 1 : ""}`}
          >
            {i + 1}
          </Link>
        );
      })}
      <Link disabled={!hasNextPage} to={`${base}/${nextPage}`}>
        Next ➡
      </Link>
    </PaginationStyles>
  );
}
