import React, { useRef } from "react";
import { graphql, Link } from "gatsby";
import Section from "../components/Section";
import styled from "styled-components";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import Pagination from "../components/Pagination/Pagination";
import ShareIcons from "../components/ShareIcons/ShareIcons";
import { below } from "../styles/Breakpoints";
import SEO from "../components/SEO";

const StyledNewsPost = styled.div`
  position: relative;
  max-width: 600px;
  width: 100%;
  margin-bottom: 7rem;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    display: inline-block;
    font-size: 1.5rem;
    font-size: clamp(1.2rem, (0.5rem + 3vw), 1.5rem);
    @supports not (font-size: clamp(1.2rem, (0.5rem + 3vw), 1.5rem)) {
      ${below.mobileL`
        font-size: 1.2rem;
      `}
    }
    text-transform: initial;
    margin-bottom: 1rem;
    text-align: left;
  }

  .gatsby-image-wrapper {
    margin-bottom: 1.5rem;
  }

  .news-date {
    font-size: 0.85rem;
    font-weight: 600;
    display: inline-block;
    margin-left: auto;
  }

  .news-post-link:hover,
  .news-post-link:focus {
    ${below.mobileL`
      color: var(--black);
    `}
  }
`;

export default function NewsListPage({ data, pageContext, location }) {
  const newsHeader = useRef();
  const posts = data.allSanityPost.nodes;
  const url = location.href;

  let postWithImageIndex;
  posts.forEach((post, i) => {
    if (post.mainImage) {
      postWithImageIndex = i;
      return;
    }
  });

  return (
    <>
      <SEO
        title="News"
        image={posts[postWithImageIndex]?.mainImage?.asset?.fluid?.src}
        location={location}
      />
      <Section width="600px">
        <h1 style={{ marginBottom: "1.2rem" }}>News</h1>
        <div className="header-underline"></div>

        {posts.map(post => (
          <StyledNewsPost key={post._id}>
            <Link
              to={post.slug ? `/news/${post.slug.current}` : "#"}
              className="news-post-link"
            >
              <h2 ref={newsHeader}>{post.title}</h2>
              {post.mainImage && <Img fluid={post.mainImage.asset.fluid} />}
            </Link>
            <div className="post-body">
              <BlockContent
                blocks={post._rawBody}
                projectId="hq5iqyv5"
                dataset="production"
                imageOptions={{ w: 600, fit: "max" }}
              />
            </div>
            <div className="post-meta-container">
              <ShareIcons
                title={post.title}
                url={`${url}/${post.slug.current}`}
              />
              <span>
                Posted by{" "}
                {/* TODO add link to either author's other posts or social media ? */}
                <a href="#" className="author-name">
                  {post.author != null ? post.author.name : "Anonymous"}
                </a>{" "}
                on <span>{post.createdFormatted}</span>
              </span>
            </div>
            <div className="post-divider"></div>
          </StyledNewsPost>
        ))}
        <Pagination
          pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
          totalCount={data.allSanityPost.totalCount}
          currentPage={pageContext.currentPage || 1}
          skip={pageContext.skip}
          base="/news"
        />
      </Section>
    </>
  );
}

export const newsQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allSanityPost(
      skip: $skip
      limit: $limit
      sort: { fields: _createdAt, order: DESC }
    ) {
      totalCount
      nodes {
        title
        slug {
          current
        }
        _id
        author {
          name
        }
        _rawBody
        _updatedAt(formatString: "MMMM DD, YYYY")
        _createdAt(formatString: "YY MM DD ")
        createdFormatted: _createdAt(formatString: "MMMM DD, YYYY")
        mainImage {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
